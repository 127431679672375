<template>
	<div ref="elRef"
		 class="button-safe-wrap">
		<template v-if="to">
			<NuxtLink ref="buttonRef"
					  :to="to"
					  :class="buttonClasses"
					  :target="target"
					  v-bind="dataAttrs"
					  @mouseenter="enter"
					  @mouseleave="leave">
				<span ref="innerRef"
					  class="inner">
					<div ref="fillerRef"
						 class="filler" />
					<span ref="textRef"
						  class="text">
						<slot>Button</slot>
					</span>
				</span>
			</NuxtLink>
		</template>

		<template v-else-if="href">
			<a ref="buttonRef"
			   :href="href"
			   :class="buttonClasses"
			   :target="target"
			   v-bind="dataAttrs"
			   @mouseenter="enter"
			   @mouseleave="leave">
				<span ref="innerRef"
					  class="inner">
					<div ref="fillerRef"
						 class="filler" />
					<span ref="textRef"
						  class="text">
						<slot>Button</slot>
					</span>
				</span>
			</a>
		</template>

		<template v-else>
			<button ref="buttonRef"
					:class="buttonClasses"
					v-bind="dataAttrs"
					@mouseenter="enter"
					@mouseleave="leave">
				<span ref="innerRef"
					  class="inner">
					<div ref="fillerRef"
						 class="filler" />
					<span ref="textRef"
						  class="text">
						<slot>Button</slot>
					</span>
				</span>
			</button>
		</template>


		<div v-if="magnetic === true"
			 ref="magnet"
			 class="magnetic-size" />
	</div>
</template>

<script setup>
import { gsap } from 'gsap'

const props = defineProps({
	cursor: {
		type: Object,
		default: () => {
			return {
				hover: true,
				custom: null,
				text: null,
				textInstant: null,
			}
		},
	},
	to: {
		type: String,
		default: null,
	},
	href: {
		type: String,
		default: null,
	},
	classes: {
		type: String,
		default: 'button',
	},
	target: {
		type: String,
		default: null,
	},
	rel: {
		type: String,
		default: null,
	},
	title: {
		type: String,
		default: null,
	},
	download: {
		type: [Boolean, String],
		default: null,
	},
	magnetic: {
		type: Boolean,
		default: true,
	},
	magneticStrength: {
		type: Number,
		default: 1,
	},
})

const elRef = ref(null)
const buttonRef = ref(null)
const innerRef = ref(null)
const textRef = ref(null)
const fillerRef = ref(null)

const state = reactive({ hover: false })
const buttonClasses = computed(() => {
	let classesString

	if (props.classes) {
		classesString = `button ${props.classes}`
	}

	if (state.hover) {
		classesString += ' button--hover'
	}

	return classesString
})

let $q

const dataAttrs = computed(() => {
	return {
		'data-cursor-hover': props.cursor.hover,
		'data-cursor-custom': props.cursor.custom,
		'data-cursor-text': props.cursor.text,
		'data-text-instant': props.cursor.textInstant,
	}
})

function enter() {
	state.hover = true

	gsap.killTweensOf(fillerRef.value)
	gsap.killTweensOf(textRef.value)

	gsap
		.timeline()
		.fromTo(fillerRef.value, {
			y: '85%',
		}, {
			y: '0%',
			duration: 0.2
		})
		.to(textRef.value, {
			y: '-10%',
			opacity: 0,
			duration: 0.1,
			ease: 'power3.out'
		}, 0)
		.fromTo(textRef.value, {
			y: '30%',
			opacity: 1
		}, {
			y: '0%',
			opacity: 1,
			ease: 'power3.out',
			duration: 0.25,
		}, 0.1)
}

function leave() {
	state.hover = false

	gsap.killTweensOf(fillerRef.value)
	gsap.killTweensOf(textRef.value)

	gsap
		.timeline()
		.to(fillerRef.value, {
			y: '-85%',
			ease: 'power3.out',
			duration: 0.4
		})
		.to(fillerRef.value, {
			// width: '200%',
			ease: 'power3.out',
			duration: 1
		})
		.to(textRef.value, {
			opacity: 0,
			y: '10%',
			ease: 'power3.out',
			duration: 0.1
		}, 0)
		.fromTo(textRef.value, {
			y: '-30%',
			opacity: 1
		}, {
			y: '0%',
			opacity: 1,
			ease: 'power3.out',
			duration: 0.25,
		}, 0.1)
}

function parallaxIt(e, movement = 0.35) {
	if (elRef.value) {
		movement = movement * props.magneticStrength
		const boundingRect = elRef.value.getBoundingClientRect()
		const relX = e.pageX - boundingRect.left
		const relY = e.pageY - boundingRect.top
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop

		gsap.killTweensOf($q('.button'))

		gsap
			.timeline()
			.to($q('.button'), {
				x: (relX - boundingRect.width / 2) * movement,
				y: (relY - boundingRect.height / 2 - scrollTop) * movement,
				ease: 'circ.out',
				duration: 0.5
			})
	}
}

function resetParallax() {
	gsap.killTweensOf($q('.button'))

	gsap
		.timeline({ overwrite: true })
		.to($q('.button'), {
			scale: 1,
			x: 0,
			y: 0,
			ease: 'power4.out',
			duration: 1
		})
}

function addEventListeners(bool) {
	if (props.magnetic && bool) {
		elRef.value.addEventListener('mousemove', e => parallaxIt(e))
		elRef.value.addEventListener('mouseleave', resetParallax)
	} else {
		elRef.value.removeEventListener('mousemove', e => callParallax(e))
		elRef.value.removeEventListener('mouseleave', resetParallax)
	}
}

onMounted(() => {
	$q = gsap.utils.selector(elRef.value)
	gsap.set(fillerRef.value, { xPercent: -50, })

	addEventListeners(true)
})

onBeforeUnmount(() => {
	addEventListeners(false)
})
</script>

<style lang="scss" scoped>
.button-safe-wrap {
	$magnetic-size: 2.5em;
	position: relative;
	display: inline-block;
	// border-radius: 50%;
	// margin: $magnetic-size;
	// border: 2px dashed #333; // Guideline to visualize the area

	.magnetic-size {
		/* width: 100%;
		height: 100%; */
		position: absolute;
		top: calc(($magnetic-size * 1) * -1);
		bottom: calc(($magnetic-size * 1) * -1);
		left: calc(($magnetic-size * 1) * -1);
		right: calc(($magnetic-size * 1) * -1);
		border-radius: 50%;
		z-index: 1;
		// border: 2px dashed #333; // Guideline to visualize the area
	}
}

.button {
	position: relative;
	z-index: 2;
	cursor: pointer;
	display: inline-block;
	justify-content: center;
	align-items: center;
	background-color: $black;
	overflow: hidden;
	border-radius: 500px;
	border-color: $black;
	border-style: solid;
	border-width: 2px;

	@include for-devices-above(md) {
		border-width: 3px;
	}

	* {
		pointer-events: none;
	}

	.inner,
	.text {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		border-radius: 500px;
		text-align: center;
		@include textPrimaryButton;
	}

	.inner {
		@include textPrimaryButton;
		padding: rem-calc(4 20 8 20);

		@include for-devices-above(lg) {
			padding: rem-calc(6 22 10 22);
		}


		.filler {
			position: absolute;
			top: -50%;
			left: 50%;
			height: 200%;
			width: 120%;
			border-radius: 50%;
			transform: translate(0, 100%);
			background: $white;
		}

		.text {
			color: $white;
		}
	}

	&:hover {
		.text {
			color: $black;
		}
	}

	&.big {
		.text {
			@include textLargeButton;
			padding: rem-calc(12 24 17 24);

			@include for-devices-above(xl) {
				padding: rem-calc(21 56 29 59);
			}
		}
	}

	&.colored {
		border-color: var(--foreground-color, $black);
		background-color: var(--foreground-color, $white);

		.filler {
			background: var(--background-color, $black);
		}

		.text {
			color: var(--background-color, $black);
		}

		&:hover {
			.text {
				color: var(--foreground-color, $white);
			}
		}
	}

	&.secondary {
		background-color: unset;
		border-color: $black;

		.filler {
			background: $black;
		}

		.text {
			color: $black;
		}

		&:hover {
			.text {
				color: $white;
			}
		}

		&.colored {
			background-color: unset;
			border-color: var(--foreground-color, $black);

			.filler {
				background: var(--foreground-color, $black);
			}

			.text {

				color: var(--foreground-color, $black);
			}

			&:hover {
				.text {
					color: var(--background-color, $black);
				}
			}
		}
	}

	&.inverted {
		background-color: $white;
		border-color: $white;

		.filler {
			background: $black;
		}

		.text {
			color: $black;
		}

		&:hover {
			.text {
				color: $white;
			}
		}

		&.secondary {
			background-color: unset;
			border-color: $white;

			.filler {
				background: $white;
			}

			.text {

				color: $white;
			}

			&:hover {
				.text {
					color: $black;
				}
			}
		}
	}
}
</style>